<template>
  <div class="home" v-if="currentRoute">
    <stage stageLabel="Jobs" :content="currentRoute.acf.facts" :title="currentRoute.title.rendered"/>
    <introduction
        fontWeight="normal"
        :maintext="currentRoute.acf.description"
        :image="currentRoute.acf.ansprechpartner.image"
        :contact="currentRoute.acf.ansprechpartner"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Stage from '@/components/stage.vue'
import Introduction from '@/components/introduction.vue'
import { mapActions, mapGetters } from 'vuex'


export default {
  name: 'Home',
  components: {
    Stage,
    Introduction
  },
  computed: {
    ...mapGetters([
        'getJobs',
        'getFetched'
    ]),
    currentRoute(){
      const getJobs =  this.getJobs.filter(obj => obj.slug === this.$route.params.name)[0];
      const getFetched =  this.getFetched[this.$route.params.name];
      return getJobs || getFetched;
    },
  },
  created() {
      if(!this.currentRoute) {
          this.fetchCurrentPost(this.$route.params.name);
      }
  },
  methods: {
    ...mapActions([
          'fetchCurrentPost',
    ]),
  }
}
</script>
<style lang="scss" scoped>
.home {
  margin-top: 86px;
  padding: 0 .8rem;
  &__content {
    max-width: 1140px;
    margin: auto;
    display: flex;
    flex-direction: column-reverse;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  &__markdown, &__contact {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  &__contact {
    max-width: 442px;
    font-size: 18px;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
  &__image {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 300px;
    margin: 1.6rem 0;
    @media (min-width: 1024px) {
      margin: 65px 0;
    }
  }
}
</style>
